import React from 'react';
import './styles/index.scss';
import PropTypes from 'prop-types';
import external from '../../assets/external.svg';

const ListItem = ({ item, externalLink }) => (
  <div className="list-item-content-wrapper text-small-bold">
    <div>{item}</div>
    {externalLink ? <img alt="Share" src={external} /> : <div>{'>'}</div>}
  </div>
);

ListItem.propTypes = {
  item: PropTypes.element.isRequired,
  externalLink: PropTypes.string,
};
ListItem.defaultProps = {
  externalLink: '',
};
export default ListItem;
