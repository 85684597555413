import * as yup from 'yup';

import {
  fieldValidatorBase,
  formValidationFactory,
  wait,
} from '../../utils/forms';
import { sendEmailCheck } from '../../api/modules/email';

const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter your name'),
  email: yup
    .string()
    .required('Please enter your email')
    .email('Invalid email'),
  tags: yup.string().required('Please enter topic'),
  message: yup.string().required('Please enter message'),
});

const fieldValidator = fieldValidatorBase(validationSchema);

let emailValidationTimestamp = null;
export const emailValidation = async (email) => {
  const timestamp = Date.now();
  emailValidationTimestamp = timestamp;

  await wait(500); // time for user to finish import
  if (emailValidationTimestamp === timestamp) {
    try {
      const {
        data: { success, suggestedEmail },
      } = await sendEmailCheck({ email });
      if (!success) {
        return [
          suggestedEmail
            ? `Did you mean ${suggestedEmail}?`
            : 'Invalid email address',
        ];
      }
    } catch (error) {
      return [error.message || error];
    }
  }
  return [];
};

export const validateName = fieldValidator('name');
export const validateEmail = fieldValidator('email', emailValidation);
export const validateTags = fieldValidator('tags');
export const validateMessage = fieldValidator('message');

export const validation = formValidationFactory(validationSchema, {
  email: emailValidation,
});
