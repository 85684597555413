import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { loginRequest } from '../../reducers/Auth';
import PageHeader from '../../components/PageHeader';
import Form from '../../components/Form/Form';
import Text from '../../components/Form/InputTypes/Text';
import { validation, validateUsername, validatePassword } from './validation';
import { REACT_APP_MAIN_RESET_PASSWORD } from '../../utils/external-urls';

const Login = ({ loginRequest, twoFactorID }) => {
  const initialState = {
    username: '',
    password: '',
  };

  if (twoFactorID) {
    return <Redirect to="/2fa/login" />;
  }

  return (
    <>
      <PageHeader text="Have an account?" title="Log in to your account" />
      <div className="mt-16">
        <Form
          initialState={initialState}
          validationFunc={validation}
          onSubmit={loginRequest}
          submitLabel="Submit"
        >
          <Text
            fieldname="username"
            label="Username"
            validationFunc={validateUsername}
          />
          <Text
            fieldname="password"
            label="Password"
            type="password"
            validationFunc={validatePassword}
          />
        </Form>
      </div>
      <div className="text-center text-small-bold">
        <p className="mt-24">
          Forgot username or password?{' '}
          <a
            rel="noopener noreferrer"
            className="text-teal"
            href={REACT_APP_MAIN_RESET_PASSWORD}
            target="_blank"
          >
            Reset.
          </a>
        </p>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  twoFactorID: state.Auth.twoFactorID,
});

const mapDispatchToProps = {
  loginRequest,
};

Login.propTypes = {
  loginRequest: PropTypes.func.isRequired,
  twoFactorID: PropTypes.string,
};

Login.defaultProps = {
  twoFactorID: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
