import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import Container from './shared/Container';
import useField from '../hooks/useField';

import './styles/Text.scss';

const Component = ({
  fieldname,
  validationFunc,
  required,
  label,
  placeholder,
  type,
  autocomplete,
  textarea,
  className,
  inputMode,
  isAmount,
}) => {
  const { value, onChange, onFocus, onBlur, isActive, errors } = useField(
    fieldname,
    {
      validationFunc,
      required,
    },
  );

  const inputRef = useRef(null);

  const handleFocus = () => {
    onFocus();
    if (typeof inputRef.current.focus !== 'undefined') {
      inputRef.current.focus();
    } else {
      inputRef.current.inputElement.focus();
    }
  };

  const handleBlur = (ref) => () => {
    onBlur();
    if (typeof ref.current.blur !== 'undefined') {
      ref.current.blur();
    } else {
      ref.current.inputElement.blur();
    }
  };

  const errorElements = errors.map((e, i) => {
    const uniqueKey = `${fieldname}-${i}`;
    return (
      <div className="error_element" key={`error-element-${uniqueKey}`}>
        {e}
      </div>
    );
  });

  const InputTag = textarea ? 'textarea' : 'input';
  const hasError = errors.length > 0;

  return (
    <div className={className ? `main ${className}` : 'main'}>
      {isAmount && !!value && (
        <div
          className={hasError ? 'amountLabel amountLabelError' : 'amountLabel'}
        >
          $
        </div>
      )}
      <Container
        onClick={handleFocus}
        onClickOutside={handleBlur(inputRef)}
        isActive={isActive}
        hasError={hasError}
        textarea={textarea}
      >
        <InputTag
          type={type}
          className={
            isAmount && !!value ? 'input_box amount_input_box' : 'input_box'
          }
          ref={inputRef}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          data-testid={fieldname}
          placeholder={isActive ? placeholder : ''}
          autoComplete={autocomplete}
          inputMode={inputMode}
        />
        <CSSTransition
          in={isActive || value !== ''}
          timeout={1000}
          classNames="textLabel"
          appear={value !== ''}
        >
          <label className="textLabel" htmlFor={fieldname}>
            {label}
          </label>
        </CSSTransition>
      </Container>
      <div className="error_box">{errorElements}</div>
    </div>
  );
};

Component.propTypes = {
  fieldname: PropTypes.string.isRequired,
  validationFunc: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  autocomplete: PropTypes.string,
  textarea: PropTypes.bool,
  className: PropTypes.string,
  inputMode: PropTypes.string,
  isAmount: PropTypes.bool,
};

Component.defaultProps = {
  validationFunc: () => [],
  required: false,
  placeholder: '',
  label: 'Label',
  type: 'text',
  autocomplete: 'none',
  textarea: false,
  className: undefined,
  inputMode: undefined,
  isAmount: false,
};

Component.displayName = 'TextInput';

export default Component;
