import React from 'react';
import { Link } from 'react-router-dom';

import LinksList from '../../components/LinksList/List';
import PageHeader from '../../components/PageHeader';
import items from './items';
import { REACT_APP_MAIN_DASHBOARD } from '../../utils/external-urls';
import { onNavigateListenerRN } from '../../utils/mobile-web-view';

const ContactUs = () => {
  function onClick() {
    onNavigateListenerRN({ source: 'helpdesk' }, REACT_APP_MAIN_DASHBOARD);
  }

  return (
    <>
      <PageHeader text="HMBradley Support" title="How can we help?" />
      <div className="mt-16">
        <LinksList items={items} />
      </div>
      <div className="text-center text-small-bold mt-24 show-mobile">
        <p>Have an account?</p>
        <p className="mt-12">
          <Link className="text-teal" to="/login">
            Log in
          </Link>{' '}
          for more support options.
        </p>
      </div>
      <div
        className="back-to-accounts-button cursor-pointer"
        onClick={onClick}
        onKeyDown={onClick}
        target="_blank"
        rel="noopener noreferrer"
        role="link"
        tabIndex={0}
      >
        Back to accounts
      </div>
    </>
  );
};

export default ContactUs;
