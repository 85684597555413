import React from 'react';
import PropTypes from 'prop-types';

import './styles/style.scss';

const Component = ({ selectProps: { menuIsOpen } }) => {
  const arrowSVG = menuIsOpen ? (
    <polygon className="dropdownArrow" points="0,8 8,0 16,8" />
  ) : (
    <polygon className="dropdownArrowp" points="0,0 8,8 16,0" />
  );

  return (
    <div className="dropdownIndicator">
      <svg width="16" height="8">
        {arrowSVG}
      </svg>
    </div>
  );
};

Component.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }).isRequired,
};

Component.displayName = 'Arrow';

export default Component;
