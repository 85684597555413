import React, { useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastContainer, toast, Slide } from 'react-toastify';

import { getUserRequest } from '../reducers/Auth';
import { setAllowUploadDocuments } from '../utils/upload-documents';
import Timer from '../components/ActivityTimer';
import Pages from '../pages';
import { ReactComponent as CloseButton } from '../assets/close.svg';
import { initPosthog } from '../utils/posthog';
import { hideWidget } from '../utils/widget-zendesk';

const ToastCloseButton = () => <CloseButton />;

const App = ({ userLoaded, getUserRequest }) => {
  const location = useLocation();

  useEffect(() => {
    if (!userLoaded) {
      getUserRequest();
      initPosthog();
    }
    if (location.search.includes('allow_upload_document=true')) {
      setAllowUploadDocuments();
    }
  }, [getUserRequest, userLoaded, location.search]);

  useEffect(() => {
    hideWidget(true);
  }, [location.pathname]);

  return (
    <>
      <Pages />
      <Timer />
      <ToastContainer
        closeButton={<ToastCloseButton />}
        position={toast.POSITION.TOP_CENTER}
        autoClose={5000}
        hideProgressBar
        transition={Slide}
      />
    </>
  );
};

const AppWithBrowserRouter = ({ userLoaded, getUserRequest }) => {
  return (
    <BrowserRouter>
      <App userLoaded={userLoaded} getUserRequest={getUserRequest} />
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  userLoaded: state.Auth.userLoaded,
});

const mapDispatchToProps = {
  getUserRequest,
};

App.propTypes = {
  getUserRequest: PropTypes.func.isRequired,
  userLoaded: PropTypes.bool.isRequired,
};

AppWithBrowserRouter.propTypes = {
  getUserRequest: PropTypes.func.isRequired,
  userLoaded: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppWithBrowserRouter);
