import React from 'react';
import { REACT_APP_MAIN_FAQ } from '../../utils/external-urls';

export default [
  {
    template: <>Frequently Asked Questions</>,
    key: 'to-asked',
    externalLink: REACT_APP_MAIN_FAQ,
  },
  {
    template: <>Email</>,
    key: 'to-email',
    to: '/support-email',
  },
];
