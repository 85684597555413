import _ from 'lodash';

// used to get nested values from validation groups
// should handle deeply nested value paths
// e.g. address.addressLnOne
const getNestedValues = (obj) => {
  if (Array.isArray(obj)) {
    return obj;
  }
  if (typeof obj !== 'object') {
    return [obj];
  }

  const childVals = _.values(obj).reduce(
    (acc, v) => acc.concat(getNestedValues(v)),
    [],
  );

  return childVals;
};

export default getNestedValues;
