export function page(pathname) {
  window.analytics.page(pathname);
}

export function identify(userID, data) {
  window.analytics.identify(userID, data);
}

export function trackEvent(eventName, data) {
  window.analytics.track(eventName, data);
}

export const FULLSTORY_MASK = 'fs-mask';
export const FULLSTORY_UNMASK = 'fs-unmask';
export const FULLSTORY_EXCLUDE = 'fs-exclude';
