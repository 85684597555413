import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { verifyEmailRequest, resendCodeRequest } from '../../reducers/Auth';
import PageHeader from '../../components/PageHeader';
import Form from '../../components/Form/Form';
import Otp from '../../components/Form/InputTypes/Otp';
import { validateCode } from './validation';
import Layout from '../../components/Layout';

const EmailVerification = ({
  verifyEmailRequest,
  resendCodeRequest,
  isVerified,
  user,
}) => {
  const [sentSuccess, setSentSuccess] = useState(false);

  const onResend = async () => {
    if (!sentSuccess) {
      try {
        await resendCodeRequest(user.email);
        setSentSuccess(true);
      } catch (error) {
        setSentSuccess(false);
      }
    }
  };

  const initialState = {
    code: '',
  };

  return (
    <Layout>
      {isVerified && <Redirect to="/topics" />}
      {!!user && (
        <>
          <PageHeader title="We've just sent a unique code to your email." />
          <p className="text-small mt-8">
            For your security, we need to verify that the email you&apos;ve
            provided belongs to you.
          </p>
          <p className="text-small mt-12">
            Please enter the one-time 6 digit code that we&apos;ve emailed to
            <span className="text-small-bold"> {user.email}</span>
          </p>

          <div className="mt-16">
            <Form
              initialState={initialState}
              validationFunc={() => []}
              onSubmit={verifyEmailRequest}
              submitLabel="Submit"
            >
              <Otp fieldname="code" validationFunc={validateCode} required />
            </Form>
          </div>
          <div
            className={`link-button mx-auto mt-16 ${
              sentSuccess ? 'disable' : ''
            }`}
            role="button"
            tabIndex={0}
            onClick={onResend}
            onKeyPress={onResend}
          >
            Resend code
          </div>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  isVerified: state.Auth.isVerified,
  user: state.Auth.user,
});
const mapDispatchToProps = {
  verifyEmailRequest,
  resendCodeRequest,
};

EmailVerification.propTypes = {
  user: PropTypes.shape(),
  isVerified: PropTypes.bool.isRequired,
  verifyEmailRequest: PropTypes.func.isRequired,
  resendCodeRequest: PropTypes.func.isRequired,
};

EmailVerification.defaultProps = {
  user: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
