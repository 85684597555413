import { toast } from 'react-toastify';
import { getCode } from '../api/modules/code';
import {
  subscribeToVerification,
  unsubscribeToVerification,
  subscribeToStatus,
  subscribeToAssigned,
  unsubscribeToStatus,
} from '../api/socket';
import { preparePhoneNumberToServer, preparePhoneNumber } from '../utils/forms';
import { generateRequester } from '../utils/user';
import { trackEvent } from '../utils/analytics';
import { NO_ACTIVE_ACCOUNT } from '../utils/toast';

export const GET_CODE_LOADING = 'GET_CODE_LOADING';
export const GET_CODE_SUCCESS = 'GET_CODE_SUCCESS';
export const GET_CODE_ERROR = 'GET_CODE_ERROR';
export const UPDATE_CODE_VERIFIED = 'UPDATE_CODE_VERIFIED';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const UPDATE_SUCCESS_STATUS = 'UPDATE_SUCCESS_STATUS';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const UPDATE_AGENT_NAME = 'UPDATE_AGENT_NAME';
export const UPDATE_PROGRESS_ERROR = 'UPDATE_PROGRESS_ERROR';
export const UPDATE_CALLING_NUMBER = 'UPDATE_CALLING_NUMBER';

export const updateProgress = (payload) => ({ type: UPDATE_PROGRESS, payload });
export const updateProgressError = (payload) => ({
  type: UPDATE_PROGRESS_ERROR,
  payload,
});
export const setPhoneNumber = (payload) => ({
  type: SET_PHONE_NUMBER,
  payload,
});
export const codeLoading = () => ({ type: GET_CODE_LOADING });
export const codeSuccess = (payload) => ({ type: GET_CODE_SUCCESS, payload });
export const codeError = (payload) => ({ type: GET_CODE_ERROR, payload });
export const updateAgentName = (payload) => ({
  type: UPDATE_AGENT_NAME,
  payload,
});
export const updateCallingNumber = (payload) => ({
  type: UPDATE_CALLING_NUMBER,
  payload,
});
export const updateCodeVerified = (payload) => ({
  type: UPDATE_CODE_VERIFIED,
  payload,
});
export const updateSuccessStatus = (payload) => ({
  type: UPDATE_SUCCESS_STATUS,
  payload,
});

export const getCodeRequest = ({
  phoneNumber: _phoneNumber,
  tags,
  user,
  person,
  accounts,
}) => async (dispatch) => {
  const phoneNumber =
    window.TEST_NUMBER || preparePhoneNumberToServer(_phoneNumber);
  try {
    dispatch(codeLoading());
    const {
      data: { code, callingNumber },
    } = await getCode({
      phoneNumber,
      tags,
      requester: generateRequester(user, person, { phoneNumber, accounts }),
    });
    if (callingNumber) {
      dispatch(updateCallingNumber(preparePhoneNumber(callingNumber)));
    }
    subscribeToVerification(
      phoneNumber,
      // eslint-disable-next-line consistent-return
      ({ error, callingNumber, verified }) => {
        if (error) {
          toast.error(error);
          unsubscribeToVerification();
          return dispatch(codeError(error));
        }
        if (callingNumber) {
          dispatch(updateCallingNumber(preparePhoneNumber(callingNumber)));
        }
        if (verified) {
          unsubscribeToVerification();
          dispatch(updateCodeVerified(true));
          trackEvent('Support Contacted', {
            category: 'support',
            action: 'supportContacted',
            label: 'phone',
          });
          subscribeToAssigned((agentName) => {
            dispatch(updateAgentName(agentName));
          });
          subscribeToStatus((status) => {
            if (status === 'completed') {
              unsubscribeToStatus();
            }
            if (status === 'failed') {
              dispatch(updateProgressError(true));
              toast.error('The call was interrupted!');
              unsubscribeToStatus();
            }
            if (status === 'in-progress') {
              dispatch(updateProgress(true));
            }
          });
        }
      },
    );
    dispatch(codeSuccess(code));
  } catch (error) {
    let message = error.message || error;
    if (message.includes('no-active-account')) {
      message = NO_ACTIVE_ACCOUNT;
    }
    toast.error(message);
    dispatch(codeError(message));
  }
};

const DEFAULT_STATE = {
  phoneNumber: '',
  code: '',
  loading: false,
  error: '',
  verified: false,
  success: false,
  progress: false,
  callingNumber: '',
  progressError: false,
  agentName: '',
};

export default function reducer(state = DEFAULT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CODE_LOADING:
      return { ...state, error: '', loading: true };
    case GET_CODE_SUCCESS:
      return { ...state, code: payload, loading: false };
    case GET_CODE_ERROR:
      return { ...state, error: payload, loading: false };
    case UPDATE_CODE_VERIFIED:
      return {
        ...state,
        verified: payload,
        callingNumber: '',
        error: '',
      };
    case UPDATE_PROGRESS:
      return { ...state, progress: payload };
    case UPDATE_PROGRESS_ERROR:
      return { ...state, progressError: payload };
    case UPDATE_SUCCESS_STATUS:
      return { ...state, success: payload, progress: false };
    case UPDATE_CALLING_NUMBER:
      return { ...state, callingNumber: payload };
    case SET_PHONE_NUMBER:
      return { ...state, phoneNumber: payload };
    case UPDATE_AGENT_NAME:
      return { ...state, agentName: payload };
    default:
      return state;
  }
}
