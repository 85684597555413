import axios from 'axios';

const {
  REACT_APP_MAINFRAME_API_URL = 'http://localhost:5000',
  REACT_APP_INTERNAL_API_URL = 'http://localhost:4001',
} = process.env;

export const mainInstance = axios.create({
  baseURL: REACT_APP_MAINFRAME_API_URL,
  withCredentials: true,
});
export const internalInstance = axios.create({
  baseURL: REACT_APP_INTERNAL_API_URL,
});

[mainInstance, internalInstance].forEach((axiosInstance) =>
  axiosInstance.interceptors.response.use(
    (data) => data,
    (error) => {
      if (error.response && error.response.data) {
        throw new Error(error.response.data.message || error.response.data);
      }
      throw new Error(error);
    },
  ),
);
