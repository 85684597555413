import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  VALIDATE_FORM,
  VALIDATE_FORM_FAILURE,
  VALIDATE_FORM_SUCCESS,
  SUBMIT_FORM,
  SUBMIT_FORM_FAILURE,
  SUBMIT_FORM_SUCCESS,
} from './formActions';
import reducer from './formReducer';
import getNestedValues from './getNestedValues';
import SubmitButton from '../Buttons/SubmitButton';

export const FormContext = React.createContext();

const Component = ({
  children,
  initialState,
  validationFunc,
  onUpdate,
  onSubmit,
  submitLabel,
  next,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    values: initialState,
    errors: [],
    focused: [],
    currentFocus: '',
    touched: [],
    required: [],
  });

  // errors that are form wide
  const submissionErrors = state.errors.filter((err) => err[0] === 'submit');

  // should be disabled if all the required fields haven't been
  // touched and if there are any input related errors
  let disabled = true;

  const numRequiredFulfilled = state.required.reduce((acc, val) => {
    // should be fulfilled if the use has entered or if the
    // value is not empty
    if (state.focused.includes(val) || _.get(state.values, val) !== '') {
      return acc + 1;
    }
    return acc;
  }, 0);

  if (
    state.errors.length === submissionErrors.length &&
    numRequiredFulfilled === state.required.length
  ) {
    disabled = false;
  }

  // some forms may have side effects that should happen
  // whenever a form value is updated
  useEffect(() => {
    onUpdate(state.values);
    // TODO: Check out cleaning this up when we get some time
    // https://github.com/facebook/create-react-app/issues/6880
    // https://reactjs.org/docs/hooks-rules.html
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, getNestedValues(state.values));

  // the validation function should return an array of
  // two dimensional arrays where the 0 index is the
  // field name and the 1 index is the error message
  //
  // "form" is the field name for form level errors
  const submit = async (e) => {
    e.preventDefault();

    dispatch({ type: VALIDATE_FORM });
    const newErrors = await validationFunc(state.values);

    if (newErrors.length === 0) {
      dispatch({ type: VALIDATE_FORM_SUCCESS });
      try {
        dispatch({ type: SUBMIT_FORM });
        await onSubmit(state.values);

        if (typeof next !== 'undefined') {
          dispatch({ type: SUBMIT_FORM_SUCCESS });

          next();
        }
      } catch (err) {
        dispatch({
          type: SUBMIT_FORM_FAILURE,
          errors: [['submit', err.message]],
        });
      }
    } else {
      dispatch({
        type: VALIDATE_FORM_FAILURE,
        errors: newErrors,
      });
    }
  };

  const provide = {
    state,
    dispatch,
  };

  const formError =
    submissionErrors.length > 0 ? (
      <div className="form_error">{submissionErrors[0][1]}</div>
    ) : null;

  return (
    <form onSubmit={submit}>
      <FormContext.Provider value={provide}>{children}</FormContext.Provider>
      <div className="error_box">{formError}</div>
      <SubmitButton
        loading={state.loading}
        disabled={disabled}
        label={submitLabel}
      />
    </form>
  );
};

Component.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  initialState: PropTypes.objectOf(PropTypes.any),
  validationFunc: PropTypes.func,
  onUpdate: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  next: PropTypes.func,
};

Component.defaultProps = {
  initialState: {},
  validationFunc: () => [],
  submitLabel: 'Submit',
  onUpdate: () => {},
  next: undefined,
};

Component.displayName = 'Form';

export default Component;
