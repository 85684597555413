import React from 'react';
import PropTypes from 'prop-types';

const ListItem = ({ item, backgroundColor, textColor, borderColor }) => (
  <div
    className="list-item-content-wrapper wire-transfer-button text-small-bold"
    style={{ backgroundColor, color: textColor, borderColor }}
  >
    <div>{item}</div>
  </div>
);

ListItem.propTypes = {
  item: PropTypes.element.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
};

ListItem.defaultProps = {
  backgroundColor: 'transparent',
  textColor: 'black',
  borderColor: 'transparent',
};

export default ListItem;
