import { useCallback, useEffect } from 'react';

const useClickOutsideHandler = (ref, handler) => {
  const handleClick = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    },
    [ref, handler],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick, ref]);
};

export default useClickOutsideHandler;
