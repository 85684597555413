import { toast } from 'react-toastify';
import { sendEmail } from '../api/modules/email';
import { generateRequester } from '../utils/user';
import { REACT_APP_MAIN_DASHBOARD } from '../utils/external-urls';
import { postMessageToWebView } from '../utils/mobile-web-view';

export const SENDING_EMAIL_ERROR = 'SENDING_EMAIL_ERROR';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';

export const updateEmailSuccess = (payload) => ({
  type: UPDATE_EMAIL_SUCCESS,
  payload,
});
export const sendingEmailError = (payload) => ({
  type: SENDING_EMAIL_ERROR,
  payload,
});

export const sendEmailRequest = ({
  name,
  email,
  tags,
  message,
  user,
  person,
}) => async (dispatch) => {
  try {
    const {
      data: { success },
    } = await sendEmail({
      message,
      tags,
      requester: generateRequester(user, person, { name, email }),
    });
    if (success) {
      dispatch(updateEmailSuccess(true));
      postMessageToWebView(
        {
          flow: 'contact-support',
          via: 'email',
          subject: tags,
          action: 'send-email',
          status: 'success',
        },
        () => window.open(REACT_APP_MAIN_DASHBOARD, '_self'),
      );
    }
  } catch (error) {
    const message = error.message || error;
    toast.error(message);
    dispatch(sendingEmailError(message));
  }
};

const DEFAULT_STATE = {
  error: '',
  success: false,
};

export default function reducer(state = DEFAULT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case SENDING_EMAIL_ERROR:
      return { ...state, error: payload };
    case UPDATE_EMAIL_SUCCESS:
      return { ...state, success: payload };
    default:
      return state;
  }
}
