import { useContext } from 'react';
import _ from 'lodash';

import { FormContext } from '../Form';

export const useFormContext = () => {
  const form = useContext(FormContext);
  const isFormChild = typeof form === 'object';

  if (isFormChild) {
    return form;
  }
  return null;
};

export const inputFromForm = (form, name) => {
  const { state, dispatch } = form;

  // groups are for values that rely on each
  // other for valiation such as pwd and confirm pwd
  const groupName = name.split('.')[0];
  const validationGroup = _.get(state, `values.${groupName}`);

  const value = _.get(state, `values.${name}`);

  const isActive = state.currentFocus === name;
  const wasFocused = state.focused.includes(name);
  const isTouched = state.touched.includes(name);

  // get all form level errors for the specific input name
  const errors = state.errors
    .filter((err) => err[0] === name)
    .map((err) => err[1]);

  // this is called by the input onChange handler
  // with the latest value
  const dispatchWithField = (actionObj) => {
    dispatch({
      ...actionObj,
      fieldname: name,
    });
  };

  return {
    value,
    dispatch: dispatchWithField,
    errors,
    wasFocused,
    isActive,
    isTouched,
    validationGroup,
  };
};
