export const postMessageToWebView = (payload, fallback = () => {}) => {
  const data = JSON.stringify({ source: 'helpdesk', ...payload });

  if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
    window.ReactNativeWebView.postMessage(data);
  } else {
    fallback();
  }
};

export function onNavigateListenerRN(options, location) {
  postMessageToWebView(options, () => {
    window.open(location, '_blank');
  });
}
