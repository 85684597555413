import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  checkAllowUploadDocuments,
  removeAllowUploadDocuments,
} from '../../../utils/upload-documents';
import { getUserName } from '../../../utils/user';
import {
  onWidgetSuccess,
  onWidgetMinimised,
  showWidget,
  prefillUserData,
  hideWidget,
} from '../../../utils/widget-zendesk';
import items from './items';
import LinksList from '../../../components/LinksList/List';
import BackButton from '../../../components/BackButton';
import PageHeader from '../../../components/PageHeader';
import { pushTag } from '../../../reducers/Tags';
import Layout from '../../../components/Layout';

const ProfileOrPreference = ({ pushTag, user }) => {
  const allowUploadDocument = checkAllowUploadDocuments();

  React.useEffect(() => {
    if (allowUploadDocument) {
      setTimeout(() => {
        showWidget();
        prefillUserData(user.email, getUserName(user, ''));
        onWidgetMinimised(() => {
          hideWidget();
        });
        onWidgetSuccess(() => {
          removeAllowUploadDocuments();
        });
      }, [500]);
    }
  }, [allowUploadDocument, user]);

  return (
    <Layout>
      <PageHeader title="Please tell us more:">
        <BackButton to="/topics" />
      </PageHeader>
      <div className="mt-16">
        <LinksList selectedCallback={pushTag} items={items} />
      </div>
    </Layout>
  );
};

const mapDispatchToProps = {
  pushTag,
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
});

ProfileOrPreference.propTypes = {
  pushTag: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    namePrefix: PropTypes.string,
    nameFirst: PropTypes.string,
    nameMiddle: PropTypes.string,
    nameLast: PropTypes.string,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileOrPreference);
