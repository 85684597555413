import _ from 'lodash';

import {
  SET_VALUE,
  SET_ERROR,
  SET_FOCUSED,
  SET_TOUCHED,
  RESET_VALUE,
  REGISTER_REQUIRED_FIELD,
} from './fieldActions';
import {
  VALIDATE_FORM,
  VALIDATE_FORM_FAILURE,
  VALIDATE_FORM_SUCCESS,
  SUBMIT_FORM,
  SUBMIT_FORM_FAILURE,
  SUBMIT_FORM_SUCCESS,
} from './formActions';

// action
//   type
//   fieldname
//   data (optional)
const formReducer = (state, action) => {
  let newErrors;
  let resetState;

  switch (action.type) {
    case SET_VALUE:
      // remove submission errors when any value changes
      newErrors = [...state.errors];
      _.remove(newErrors, (err) => err[0] === 'submit');

      return _.set(
        {
          ...state,
          errors: newErrors,
        }, // create new state obj
        `values.${action.fieldname}`,
        action.data,
      );
    case SET_ERROR:
      // clear old errors for field
      newErrors = state.errors.filter((err) => err[0] !== action.fieldname);
      return {
        ...state,
        errors: newErrors.concat(action.errors),
      };
    case SET_FOCUSED:
      if (state.focused.includes(action.fieldname)) {
        return {
          ...state,
          currentFocus: action.fieldname,
        };
      }

      return {
        ...state,
        focused: [...state.focused, action.fieldname],
        currentFocus: action.fieldname,
      };
    case SET_TOUCHED:
      if (state.touched.includes(action.fieldname)) {
        return {
          ...state,
          currentFocus: '',
        };
      }

      if (state.focused.includes(action.fieldname)) {
        return {
          ...state,
          touched: [...state.touched, action.fieldname],
          currentFocus: '',
        };
      }

      return state;
    case REGISTER_REQUIRED_FIELD:
      if (state.required.includes(action.fieldname)) {
        return state;
      }

      return {
        ...state,
        required: [...state.required, action.fieldname],
      };
    case RESET_VALUE:
      resetState = {
        ...state,
        focused: state.focused.filter((f) => f !== `${action.fieldname}`),
        touched: state.touched.filter((f) => f !== `${action.fieldname}`),
      };

      return _.set(resetState, `values.${action.fieldname}`, '');

    case VALIDATE_FORM:
      return {
        ...state,
        loading: true,
      };
    case VALIDATE_FORM_FAILURE:
      return {
        ...state,
        errors: action.errors,
        loading: false,
      };
    case VALIDATE_FORM_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
      };
    case SUBMIT_FORM:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_FORM_FAILURE:
      return {
        ...state,
        errors: action.errors,
        loading: false,
      };
    case SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        errors: [],
        loading: false,
      };
    default:
      return state;
  }
};

export default formReducer;
