import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import PrivateRoute from '../components/PrivateRoute';

import ContactUs from './ContactUs';
import Login from './Login';
import Topics from './Topics';
import Hmbradley from './Topics/Hmbradley';
import DebitCard from './Topics/Hmbradley/DebitCard';
import DepositAccount from './Topics/Hmbradley/DepositAccount';
import ProfilePreferences from './Topics/ProfileOrPreferences';
import ReplaceCard from './Topics/Hmbradley/DebitCard/ReplaceCard';
import TransactionHelp from './Topics/Hmbradley/DebitCard/TransactionHelp';
import SupportPortalHome from './SupportPortalHome';
import SupportEmail from './SupportEmail';
// import SupportPhone from './SupportPhone';
// import VerificationCode from './SupportPhone/VerificationCode';
import Verified from './SupportPhone/Verified';
import EmailVerification from './EmailVerification';
import SplitScreen from './SplitScreen';
import Login2FA from './Login2FA';
import CreditAccount from './Topics/CreditAccount';
import CreditCardSupport from './Topics/CreditAccount/CreditCardSupport';
import CreditAccountSupport from './Topics/CreditAccount/CreditAccountSupport';
import CreditReplaceCard from './Topics/CreditAccount/CreditCardSupport/CreditReplaceCard';
import CreditTransactionHelp from './Topics/CreditAccount/CreditCardSupport/CreditTransactionHelp';

import {
  page as analyticsPageFire,
  FULLSTORY_UNMASK,
} from '../utils/analytics';
import WireDetails from './WireDetails';

const contactUsScreens = [
  {
    key: 'Login',
    isMobileScreen: false,
    Component: <Login />,
  },
  {
    key: 'ContactUs',
    isMobileScreen: true,
    Component: <ContactUs />,
  },
];

const loginScreens = [
  {
    key: 'Login',
    isMobileScreen: true,
    Component: <Login />,
  },
  {
    key: 'ContactUs',
    isMobileScreen: false,
    Component: <ContactUs />,
  },
];

const useLocationChangeHandler = () => {
  const location = useLocation();
  useEffect(() => {
    analyticsPageFire(location.pathname);
  }, [location]);
};

const Pages = ({
  user,
  userLoaded,
  needVerify,
  twoFactorID,
  login2FAError,
}) => {
  useLocationChangeHandler();

  const userRoot = needVerify ? '/email-verification' : '/topics';
  const targetRoot = user ? userRoot : '/contact-us';

  return (
    <>
      <div className={`app-container ${FULLSTORY_UNMASK}`}>
        <Header />
        {userLoaded && (
          <Switch>
            {needVerify && (
              <Route
                component={EmailVerification}
                path="/email-verification"
                exact
              />
            )}
            {!needVerify && (
              <Route component={SupportEmail} path="/support-email" exact />
            )}
            {(twoFactorID || login2FAError) && (
              <Route component={Login2FA} path="/2fa/login" exact />
            )}
            {!user && (
              <Route
                exact
                path="/contact-us"
                render={() => <SplitScreen screens={contactUsScreens} />}
              />
            )}
            {!user && (
              <Route
                exact
                path="/login"
                render={() => <SplitScreen screens={loginScreens} />}
              />
            )}
            <PrivateRoute component={Topics} path="/topics" exact />
            <PrivateRoute component={DebitCard} path="/debit-card" exact />
            <PrivateRoute
              component={Hmbradley}
              path="/hmbradley-account"
              exact
            />
            <PrivateRoute
              component={DepositAccount}
              path="/deposit-account"
              exact
            />
            <PrivateRoute component={WireDetails} path="/wire-details" exact />
            <PrivateRoute
              component={ProfilePreferences}
              path="/profile-or-preferences"
              exact
            />
            <PrivateRoute component={ReplaceCard} path="/replace-card" exact />
            <PrivateRoute
              component={TransactionHelp}
              path="/transaction-help"
              exact
            />
            <PrivateRoute
              component={SupportPortalHome}
              path="/support-portal"
              exact
            />
            {/* <PrivateRoute
              component={SupportPhone}
              path="/support-phone"
              exact
            />
            <PrivateRoute
              component={VerificationCode}
              path="/verification-code"
              exact
            /> */}
            <PrivateRoute
              component={CreditAccount}
              path="/credit-account"
              exact
            />
            <PrivateRoute
              component={CreditCardSupport}
              path="/credit-card-support"
              exact
            />
            <PrivateRoute
              component={CreditAccountSupport}
              path="/credit-account-support"
              exact
            />
            <PrivateRoute
              component={CreditReplaceCard}
              path="/credit-replace-card"
              exact
            />
            <PrivateRoute
              component={CreditTransactionHelp}
              path="/credit-transaction-help"
              exact
            />
            <PrivateRoute component={Verified} path="/Verified" exact />
            <Route path="*" render={() => <Redirect to={targetRoot} />} />
          </Switch>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { user } = state.Auth;
  const { isVerified } = state.Auth;

  return {
    user: state.Auth.user,
    userLoaded: state.Auth.userLoaded,
    needVerify: !!user && !isVerified,
    twoFactorID: state.Auth.twoFactorID,
    login2FAError: state.Auth.login2FAError,
  };
};

Pages.propTypes = {
  user: PropTypes.shape(),
  login2FAError: PropTypes.string,
  userLoaded: PropTypes.bool.isRequired,
  needVerify: PropTypes.bool.isRequired,
  twoFactorID: PropTypes.string.isRequired,
};

Pages.defaultProps = {
  user: null,
  login2FAError: '',
};

export default connect(mapStateToProps)(Pages);
