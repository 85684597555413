import React from 'react';
import PropTypes from 'prop-types';
import OTPInput from 'otp-input-react';

import useField from '../hooks/useField';
import { FULLSTORY_MASK } from '../../../utils/analytics';

import './styles/OTP.scss';

// mcfly:
//   inputStyles is necessary to override default
//   inline styles assigned by the package
const inputStyles = {
  width: '100%',
  height: '48px',
  marginRight: '0',
  marginLeft: '0',
};

const Component = ({ validationFunc, fieldname, required }) => {
  const { value, onChange, onFocus, errors } = useField(fieldname, {
    validationFunc,
    required,
  });

  const errorElements = errors.map((e, i) => {
    const uniqueKey = `${fieldname}-${i}`;
    return <div key={`error-element-${uniqueKey}`}>{e}</div>;
  });

  return (
    <div className={`otp_container ${FULLSTORY_MASK}`}>
      <OTPInput
        value={value}
        onChange={(code) => {
          onFocus();
          onChange(code);
        }}
        autoFocus
        OTPLength={6}
        otpType="number"
        className="otp_input"
        inputClassName="otp_input_field"
        inputStyles={inputStyles}
        disabled={false}
      />
      <div>{errorElements}</div>
    </div>
  );
};

Component.propTypes = {
  validationFunc: PropTypes.func.isRequired,
  fieldname: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

Component.defaultProps = {
  required: false,
};

Component.displayName = 'OtpInput';

export default Component;
