import React from 'react';

export default [
  {
    template: (
      <>
        I need to <span className="text-teal">replace my card</span>
      </>
    ),
    to: '/replace-card',
    key: 'replace',
  },
  {
    template: (
      <>
        I need to set/change my <span className="text-teal">PIN</span>
      </>
    ),
    to: '/support-portal',
    key: 'pin',
  },
  {
    template: (
      <>
        I need to <span className="text-teal">lock/unlock</span> my card
      </>
    ),
    to: '/support-portal',
    key: 'lockunlock',
  },
  {
    template: (
      <>
        I need help with a <span className="text-teal">transaction</span>
      </>
    ),
    to: '/transaction-help',
    key: 'transactions',
  },
  {
    template: (
      <>
        I suspect <span className="text-teal">fraud</span>
      </>
    ),
    to: '/support-portal',
    key: 'fraud',
  },
  {
    template: (
      <>
        I need help with <span className="text-teal">something else</span>
      </>
    ),
    transparent: true,
    to: '/support-portal',
    key: 'other',
  },
];
