import React from 'react';

export default [
  {
    template: <>Submit a request</>,
    transparent: true,
    externalLink:
      'https://secure.hmbradley.com/account/deposit/transfers?tab=2',
    key: 'other',
    backgroundColor: '#006982',
    textColor: '#D7DDE2',
    borderColor: '#006982',
  },
  {
    template: <>Contact support</>,
    transparent: true,
    to: '/support-portal',
    key: 'other',
    backgroundColor: '#eeeeee',
    textColor: '#1A2428',
    borderColor: '#D7DDE2',
  },
];
