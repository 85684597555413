import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

const Modal = ({ closeModal, children, isOpen, styles }) => (
  <div>
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{ customStyles, ...styles }}
      contentLabel="Example Modal"
    >
      {children}
    </ReactModal>
  </div>
);

Modal.propTypes = {
  closeModal: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isOpen: PropTypes.bool.isRequired,
  styles: PropTypes.shape(),
};

Modal.defaultProps = {
  styles: {},
  closeModal: () => {},
  children: [],
};
export default Modal;
