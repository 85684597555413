import { phoneNumberToInteger } from './forms';

export const getUserName = (user, defaultValue) => {
  if (user.nameFirst && user.nameLast) {
    return `${user.nameFirst} ${user.nameLast}`;
  }
  return defaultValue !== undefined ? defaultValue : user.email;
};

export const generateRequester = (
  user,
  person,
  { phoneNumber, email, name, accounts },
) => ({
  name: name || getUserName(user) || '',
  email:
    email ||
    user.email ||
    (!!person && person.emails && person.emails.length
      ? person.emails[0].email
      : ''),
  user_fields: {
    first_name: user ? user.nameFirst : '',
    last_name: user ? user.nameLast : '',
    phone_number: phoneNumberToInteger(
      !!person && person.phones && person.phones.length
        ? person.phones[0].phone
        : phoneNumber || '',
    ),
    callback_number: phoneNumberToInteger(phoneNumber || ''),
  },
  PACN: person ? person.id : '',
  bank_account_number:
    !!accounts && accounts.length ? accounts[0].accountNumber : '',
});
