import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { identify as analyticsIdentifyFire } from '../../utils/analytics';

const PrivateRoute = ({
  component: Component,
  user,
  needVerify,
  path,
  tags,
  exact,
  userLoaded,
  twoFactorID,
}) => {
  let render = null;

  if (twoFactorID) {
    render = <Redirect to="/2fa/login" />;
  } else if (
    user &&
    userLoaded &&
    !needVerify &&
    !tags.length &&
    path !== '/topics'
  ) {
    render = <Redirect to="/topics" />;
  } else if (user && needVerify) {
    render = <Redirect to="/email-verification" />;
  } else if (user) {
    analyticsIdentifyFire(user.userID);
    render = <Component />;
  } else {
    render = <Redirect to="/contact-us" />;
  }

  return <Route path={path} exact={exact} render={() => render} />;
};

const mapStateToProps = (state) => {
  const { user } = state.Auth;
  const { isVerified, userLoaded } = state.Auth;

  return {
    user,
    tags: state.Tags.tags,
    needVerify: !!user && !isVerified,
    userLoaded,
  };
};

PrivateRoute.propTypes = {
  user: PropTypes.shape(),
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  needVerify: PropTypes.bool.isRequired,
  userLoaded: PropTypes.bool.isRequired,
  twoFactorID: PropTypes.string,
};

PrivateRoute.defaultProps = {
  user: null,
  exact: false,
  twoFactorID: '',
};

export default connect(mapStateToProps)(PrivateRoute);
