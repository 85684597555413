import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Modal from '../Modal';
import style from './ActivityTimer.module.scss';
import Button from '../Buttons/SubmitButton';
import { logoutRequest } from '../../reducers/Auth';
import 'react-circular-progressbar/dist/styles.css';
import COLORS from '../../styles/colors';

const ModalStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1001',
    background: 'rgba(171,171,171,0.75)',
  },
  content: {
    position: 'relative',
    maxWidth: '400px',
    maxHeight: '300px',
    width: '100%',
    height: '100%',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    overflow: 'hidden',
  },
};

const events = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
];

const defaultActivityTimerDuration = 1000 * 60 * 9;
const defaultLogoutDuration = 60;

let intervalFunc = null;

const ActivityTimer = ({ logoutRequest, user }) => {
  const [modal, setModal] = useState(false);
  const [logoutTimerCountdown, setLogoutTimerCountdown] = useState(
    defaultLogoutDuration,
  );
  const refTimer = useRef(null);
  const reset = () => {
    setModal(false);
    clearInterval(intervalFunc);
    setLogoutTimerCountdown(defaultLogoutDuration);
  };
  const logoutUser = useCallback(() => {
    reset();
    logoutRequest();
  }, [logoutRequest]);
  const onIdle = () => {
    setModal(true);
    clearInterval(intervalFunc);
    intervalFunc = setInterval(() => {
      setLogoutTimerCountdown((value) => value - 1);
    }, 1000);
  };

  useEffect(() => {
    if (logoutTimerCountdown < 1) {
      logoutUser();
    }
  }, [logoutTimerCountdown, logoutUser]);

  if (user === null) return null;

  return (
    <div>
      <IdleTimer
        ref={refTimer}
        element={document}
        onIdle={onIdle}
        events={events}
        debounce={250}
        timeout={defaultActivityTimerDuration}
      />
      <Modal isOpen={modal} styles={ModalStyles}>
        <div className={style.Modal}>
          <p className={style.Title}>Looks like you've left us...</p>
          <div className={style.Block}>
            <div className={style.CounterWrapper}>
              <div className={style.Loader}>
                <CircularProgressbar
                  styles={buildStyles({
                    pathColor: COLORS.orient,
                  })}
                  value={logoutTimerCountdown}
                  maxValue={defaultLogoutDuration}
                />
              </div>
              <p className={style.Counter}>{logoutTimerCountdown}</p>
            </div>
            <p className={style.Subtitle}>
              For your security, we’ll log you out in just a moment. Click to
              stay logged in or log out now below.
            </p>
          </div>
          <div className={style.Buttons}>
            <Button
              onClick={reset}
              className={style.Button}
              label="Stay logged in"
            />
            <div
              className="link-button mx-auto mt-12"
              role="button"
              tabIndex={0}
              onClick={logoutUser}
              onKeyDown={logoutUser}
            >
              Logout
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
});

ActivityTimer.propTypes = {
  user: PropTypes.shape(),
  logoutRequest: PropTypes.func.isRequired,
};
ActivityTimer.defaultProps = {
  user: null,
};

export default connect(mapStateToProps, {
  logoutRequest,
})(withRouter(ActivityTimer));
