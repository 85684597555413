import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import Tags from './Tags';
import Code from './Code';
import Auth from './Auth';
import Email from './Email';

const withDevTools =
  process.env.REACT_APP_ENV !== 'production'
    ? composeWithDevTools(applyMiddleware(thunk))
    : applyMiddleware(thunk);

export default createStore(
  combineReducers({
    Tags,
    Code,
    Auth,
    Email,
  }),
  withDevTools,
);
