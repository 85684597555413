import { mainInstance } from '../http';

export const login = (requestData) =>
  mainInstance.post('/registrar/v1/auth/login', requestData);
export const logout = () => mainInstance.post('/registrar/v1/auth/logout');
export const getPerson = () => mainInstance.get('/registrar/v1/person');
export const getUser = () => mainInstance.get('/registrar/v1/user');
export const verifyEmail = (requestData) =>
  mainInstance.post('/registrar/v1/verifications/email', requestData);
export const resendVerificationCode = (params) =>
  mainInstance.get('/registrar/v1/verifications/email', { params });
export const login2FA = (requestData) =>
  mainInstance.post('/registrar/v1/auth/2fa', requestData);
export const resend2FACode = (requestData) =>
  mainInstance.post('/registrar/v1/2fa/code', requestData);
export const getAccounts = () => mainInstance.get('/ledger/v1/accounts');
