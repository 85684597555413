import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Arrow from './Arrow';
import useField from '../../hooks/useField';

import COLORS from '../../../../styles/colors';
import './styles/style.scss';

// TODO mcfly:
//   react-select may have a css-modules
//   solution but it will require more time
//   to research and configure with state
//   based styling
//
// React-select uses emotion
// https://react-select.com/styles
const customStyles = ({ hasError }) => ({
  container: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => {
    let borderColor = COLORS.white;

    if (state.isFocused) {
      borderColor = COLORS.darkGrey;
    }
    if (hasError) {
      borderColor = COLORS.redOrange;
    }

    return {
      ...provided,
      height: '48px',
      padding: '0 6px', // react-select has an inaccessible 2px padding
      border: `1px solid ${borderColor}`,
      boxShadow: 'none',
      ':hover': {
        border: `1px solid ${borderColor}`,
      },
    };
  },
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'Plain, san-serif',
    fontSize: '14px',
    fontWeight: '500',
    color: COLORS.darkGrey,
    padding: '0',
  }),
  option: () => ({
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    padding: '0 16px',
    fontFamily: 'Helvetica, san-serif',
    fontSize: '14px',
    fontWeight: '700',
    color: COLORS.brandTeal,
    ':hover': {
      backgroundColor: 'rgba(150, 250, 181, 0.3)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'Helvetica, san-serif',
    fontSize: '14px',
    fontWeight: '700',
    color: COLORS.darkGrey,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: 'Helvetica, san-serif',
    fontSize: '14px',
    fontWeight: '700',
    color: COLORS.darkGrey,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    border: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    border: 'none',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontFamily: 'Helvetica, san-serif',
    fontSize: '14px',
    fontWeight: '700',
    color: '#989898',
  }),
});

const Component = ({
  fieldname,
  fieldLabel,
  validationFunc,
  required,
  options,
}) => {
  const { value, onChange, onBlur, onFocus, errors } = useField(fieldname, {
    validationFunc,
    required,
  });

  const optionFields = options.map((opt) => ({
    value: opt.value,
    label: opt.label,
  }));

  const select = (opt) => onChange(opt.value);
  const selected = options.filter((o) => o.value === value);

  const errorElements = errors.map((e, i) => {
    const uniqueKey = `${fieldname}-${i}`;
    return <div key={`error-element-${uniqueKey}`}>{e}</div>;
  });

  return (
    <div>
      <Select
        placeholder={fieldLabel}
        value={selected}
        options={optionFields}
        onChange={select}
        onFocus={onFocus}
        onBlur={onBlur}
        styles={customStyles({ hasError: errors.length > 0 })}
        components={{
          DropdownIndicator: Arrow,
        }}
        closeMenuOnSelect
      />
      <div className="error_box">{errorElements}</div>
    </div>
  );
};

Component.displayName = 'Dropdown';

Component.propTypes = {
  fieldname: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  validationFunc: PropTypes.func,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
};

Component.defaultProps = {
  validationFunc: () => [],
  required: false,
};

export default Component;
