import React, { useEffect } from 'react';

import queryString from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { sendEmailRequest, sendingEmailError } from '../../reducers/Email';
import BackButton from '../../components/BackButton';
import Form from '../../components/Form/Form';
import Text from '../../components/Form/InputTypes/Text';
import Dropdown from '../../components/Form/InputTypes/Dropdown';
import {
  validation,
  validateName,
  validateEmail,
  validateTags,
  validateMessage,
} from './validation';
import { getUserName } from '../../utils/user';
import PageHeader from '../../components/PageHeader';
import { tagsOptions } from '../../utils/tags';
import Layout from '../../components/Layout';

const tagsToReplace = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
};

export function preventHtml(value) {
  return value.replace(/[&<>]/g, (tag) => tagsToReplace[tag] || tag);
}

const SupportEmail = ({
  history,
  user,
  person,
  tags,
  sendEmailRequest,
  error,
  sendingEmailError,
  success,
}) => {
  const backCallback = () => {
    history.goBack();
  };

  const submit = (data) =>
    sendEmailRequest({
      ...data,
      name: preventHtml(data.name),
      email: preventHtml(data.email),
      message: preventHtml(data.message),
      tags: JSON.parse(data.tags),
      user,
      person,
    });

  const location = useLocation();
  const { tag } = queryString.parse(location.search);

  useEffect(() => {
    return () => {
      window.location.search = '';
    };
  }, []);

  const initialState = {
    name: '',
    email: '',
    tags: JSON.stringify([...tags, tag]),
    message: '',
  };

  if (tag) {
    const tagsFromUrl = tag.split(',');
    Object.assign(initialState, {
      tags: JSON.stringify(tagsFromUrl),
    });
  }
  if (user) {
    Object.assign(initialState, {
      name: getUserName(user, ''),
      email: user.email,
    });

    if (tags.length) {
      Object.assign(initialState, {
        tags: JSON.stringify(tags),
      });
    } else if (tag) {
      const tagsFromUrl = tag.split(',');
      Object.assign(initialState, {
        tags: JSON.stringify(tagsFromUrl),
      });
    }
  }

  useEffect(() => {
    if (error) {
      history.push('support-portal');
      sendingEmailError('');
    }
  });

  return (
    <Layout>
      {success && <Redirect to="/topics" />}
      {error && <Redirect to="/support-portal" />}
      <PageHeader title="Email us">
        <BackButton clickCallback={backCallback} />
      </PageHeader>
      <p className="text-small mt-8">
        Please give us some detail on your specific issue. We usually respond
        within 24 hours.
      </p>
      <div className="mt-16">
        <Form
          initialState={initialState}
          validationFunc={validation}
          onSubmit={submit}
          submitLabel="Submit"
        >
          <Text fieldname="name" label="Name" validationFunc={validateName} />
          <Text
            fieldname="email"
            label="Email"
            validationFunc={validateEmail}
          />
          <Dropdown
            fieldname="tags"
            fieldLabel="Topic"
            options={tagsOptions}
            validationFunc={validateTags}
          />
          <Text
            textarea
            fieldname="message"
            label="Message"
            validationFunc={validateMessage}
          />
        </Form>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  person: state.Auth.person,
  tags: state.Tags.tags,
  error: state.Email.error,
  success: state.Email.success,
});

const mapDispatchToProps = {
  sendEmailRequest,
  sendingEmailError,
};

SupportEmail.propTypes = {
  sendEmailRequest: PropTypes.func.isRequired,
  sendingEmailError: PropTypes.func.isRequired,
  user: PropTypes.shape({
    nameFirst: PropTypes.string,
    nameLast: PropTypes.string,
    email: PropTypes.string,
  }),
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  history: PropTypes.shape().isRequired,
  error: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  person: PropTypes.shape(),
};

SupportEmail.defaultProps = {
  user: null,
  person: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportEmail);
