import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BackButton from '../../components/BackButton';
import PageHeader from '../../components/PageHeader';
import Layout from '../../components/WireTransferLayout';
import { popTag, pushTag } from '../../reducers/Tags';
import LinksList from '../../components/LinksList/WireTransferList';
import items from './items';

const WireDetails = ({ pushTag, popTag }) => {
  return (
    <Layout>
      <PageHeader title="Submit an outgoing wire request">
        <BackButton clickCallback={popTag} to="/deposit-account" />
      </PageHeader>
      <div className="mt-16">
        <p className="text-small">
          You can now submit an outgoing wire request and view request statuses
          in the app by navigating to the transfers page.
        </p>
        <p className="text-small mt-24">
          If you have questions about an existing wire request, please reach
          out.
        </p>
      </div>
      <div className="left-align">
        <LinksList selectedCallback={pushTag} items={items} />
      </div>
    </Layout>
  );
};

const mapDispatchToProps = {
  pushTag,
  popTag,
};

WireDetails.propTypes = {
  pushTag: PropTypes.func.isRequired,
  popTag: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(WireDetails);
