import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import items from './items';
import LinksList from '../../../../../components/LinksList/List';
import BackButton from '../../../../../components/BackButton';
import PageHeader from '../../../../../components/PageHeader';
import { pushTag, popTag } from '../../../../../reducers/Tags';
import Layout from '../../../../../components/Layout';

const TransactionHelp = ({ pushTag, popTag }) => (
  <Layout>
    <PageHeader title="Please select issues with your transaction">
      <BackButton to="/credit-card-support" clickCallback={popTag} />
    </PageHeader>
    <p className="text-small mt-8">
      Before we can assist you, we need a bit more information.
    </p>
    <div className="mt-16">
      <LinksList selectedCallback={pushTag} items={items} />
    </div>
  </Layout>
);

const mapDispatchToProps = {
  pushTag,
  popTag,
};

TransactionHelp.propTypes = {
  pushTag: PropTypes.func.isRequired,
  popTag: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(TransactionHelp);
