import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ListItem from './ListItem';
import './styles/index.scss';

const LinksList = ({ items, selectedCallback, history }) => {
  const selectItem = (item) => {
    if (selectedCallback) {
      selectedCallback(item.key);
    }
    if (item.to) {
      history.push(item.to);
    }
    if (item.externalLink) window.open(item.externalLink);
    if (item.onClick) {
      item.onClick();
    }
  };

  return items.map((item) => (
    <div
      data-testid={`testId-${item.key}`}
      role="button"
      tabIndex={0}
      onKeyDown={selectItem.bind(null, item)}
      onClick={selectItem.bind(null, item)}
      className={`cursor-pointer text-decoration-none list-item-wrapper ${
        item.transparent ? 'transparent' : ''
      }`}
      key={item.key}
    >
      <ListItem item={item.template} externalLink={item.externalLink} />
    </div>
  ));
};

LinksList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      transparent: PropTypes.bool,
      key: PropTypes.string.isRequired,
      template: PropTypes.element.isRequired,
    }),
  ).isRequired,
  selectedCallback: PropTypes.func,
};

export default withRouter(LinksList);
