import { trackEvent } from '../utils/analytics';

export const PUSH_TAG = 'PUSH_TAG';
export const POP_TAG = 'POP_TAG';
export const CLEAR_TAGS = 'CLEAR_TAGS';

export const pushTag = (payload) => ({ type: PUSH_TAG, payload });
export const popTag = (payload) => ({ type: POP_TAG, payload });
export const clearTags = () => ({ type: CLEAR_TAGS });

const DEFAULT_STATE = {
  tags: [],
};

export default function reducer(state = DEFAULT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case PUSH_TAG: {
      trackEvent('User added tag!', { tag: payload });
      return { ...state, tags: [...state.tags, payload] };
    }
    case POP_TAG: {
      const { tags } = state;
      const tag = tags.pop();
      trackEvent('User removed tag!', { tag });
      return { ...state, tags };
    }
    case CLEAR_TAGS: {
      trackEvent('Tags were cleared!');
      return { ...state, tags: [] };
    }
    default:
      return state;
  }
}
