import { useContext, useEffect } from 'react';

import {
  SET_ERROR,
  SET_FOCUSED,
  SET_TOUCHED,
  SET_VALUE,
  REGISTER_REQUIRED_FIELD,
} from '../fieldActions';
import { useFormContext, inputFromForm } from './useForm';
import { FieldContext } from '../Field';
import getNestedValues from '../getNestedValues';

const useFieldContext = () => {
  const field = useContext(FieldContext);
  const isFieldChild = typeof field === 'object';

  if (isFieldChild) {
    return field;
  }
  return null;
};

const inputFromField = (field) => {
  const { state, dispatch } = field;

  return {
    value: state.value,
    dispatch,
    errors: state.errors,
    isTouched: state.touched,
    isActive: state.focused,
    validationGroup: state.value,
  };
};

// Adds state and error handling to basic input fields
const useField = (fieldname, options = {}) => {
  const { validationFunc = () => [], required = false } = options;

  // SETUP
  const form = useFormContext();
  const field = useFieldContext();
  let getInput;

  if (form !== null) {
    getInput = () => inputFromForm(form, fieldname);
  } else {
    if (field === null) {
      throw new Error(
        'useField must be the child of a FormContext.Provider or a FieldContext.Provider',
      );
    }

    getInput = () => inputFromField(field);
  }

  const {
    value,
    dispatch,
    errors,
    isActive,
    isTouched,
    validationGroup,
  } = getInput();

  if (required) {
    dispatch({ type: REGISTER_REQUIRED_FIELD });
  }

  // INPUT HANDLERS
  const onChange = async (e) => {
    let newVal;
    // handles usage directly on an input element
    // or on a component that passes the new value
    if (typeof e.target !== 'undefined') {
      newVal = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    } else newVal = e;

    dispatch({
      type: SET_VALUE,
      data: newVal,
    });
  };

  const onFocus = () => {
    dispatch({ type: SET_FOCUSED });
  };

  const onBlur = () => dispatch({ type: SET_TOUCHED });

  // VALIDATION
  const validate = async (val) => {
    const newErrors = await validationFunc(val);

    dispatch({
      type: SET_ERROR,
      errors: newErrors.map((err) => [fieldname, err]),
    });
  };

  // build an array of values that useEffect should check
  // to determine if it should call validate
  const dependencies = getNestedValues(validationGroup);

  useEffect(() => {
    validate(validationGroup);
    // TODO: Check out cleaning this up when we get some time
    // https://github.com/facebook/create-react-app/issues/6880
    // https://reactjs.org/docs/hooks-rules.html
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return {
    value,
    onChange,
    onFocus,
    onBlur,
    isActive,
    errors: isTouched ? errors : [],
  };
};

export default useField;
