const options = [
  {
    key: ['hmbaccount', 'depositaccountsupport', 'interestrates'],
    label: 'Deposit Account / Interest Rates',
  },
  {
    key: ['hmbaccount', 'depositaccountsupport', 'plans'],
    label: 'Deposit Account / Plans',
  },
  {
    key: ['hmbaccount', 'depositaccountsupport', 'transfers'],
    label: 'Deposit Account / Transfers',
  },
  {
    key: ['hmbaccount', 'depositaccountsupport', 'linkedaccounts'],
    label: 'Deposit Account / Linked Accounts',
  },
  {
    key: ['hmbaccount', 'depositaccountsupport', 'mobilecheckdeposit'],
    label: 'Deposit Account / Mobile Check Deposit',
  },
  {
    key: ['hmbaccount', 'depositaccountsupport', 'directdeposit'],
    label: 'Deposit Account / Direct Deposit',
  },
  {
    key: ['hmbaccount', 'depositaccountsupport', 'depo/wire_request'],
    label: 'Deposit Account / Requesting a wire',
  },
  {
    key: ['hmbaccount', 'depositaccountsupport', 'other'],
    label: 'Deposit Account / Something else',
  },
  {
    key: ['hmbcreditcard', 'creditaccountsupport', 'rewards'],
    label: 'Credit Account / Rewards',
  },
  {
    key: ['hmbcreditcard', 'creditaccountsupport', 'payments'],
    label: 'Credit Account / Payments',
  },
  {
    key: ['hmbcreditcard', 'creditaccountsupport', 'fraud'],
    label: 'Credit Account / Fraud',
  },
  {
    key: ['hmbcreditcard', 'creditcardsupport', 'pin'],
    label: 'Credit Card / PIN',
  },
  {
    key: ['hmbcreditcard', 'creditcardsupport', 'lockunlock'],
    label: 'Credit Card / Lock/Unlock',
  },
  {
    key: ['hmbcreditcard', 'creditcardsupport', 'replace', 'loststolen'],
    label: 'Credit Card / Replace / Stolen',
  },
  {
    key: ['hmbcreditcard', 'creditcardsupport', 'replace', 'damaged'],
    label: 'Credit Card / Replace / Damaged',
  },
  {
    key: ['hmbcreditcard', 'creditcardsupport', 'replace', 'other'],
    label: 'Credit Card / Replace / Something else',
  },
  {
    key: ['hmbcreditcard', 'creditcardsupport', 'transactions', 'dispute'],
    label: 'Credit Card / Transaction / Dispute',
  },
  {
    key: ['hmbcreditcard', 'creditcardsupport', 'transactions', 'other'],
    label: 'Credit Card / Transaction / Something else',
  },
  {
    key: ['hmbcreditcard', 'creditcardsupport', 'other'],
    label: 'Credit Card / Something else',
  },
  {
    key: ['hmbcreditcard', 'creditaccountsupport', 'other'],
    label: 'Credit Card / Something else',
  },
  {
    key: ['hmbocc'],
    label: 'Credit Score',
  },
  {
    key: ['hmbcreditutil'],
    label: 'Credit Utilization',
  },
  {
    key: ['profilepreferences', 'personalinformation'],
    label: 'Profile or Preferences / Profile',
  },
  {
    key: ['profilepreferences', 'other'],
    label: 'Profile or Preferences / Something else',
  },
  {
    key: ['bugreport'],
    label: 'Report a bug',
  },
  {
    key: ['other'],
    label: 'Something else',
  },
];

export const tagsOptions = options.map(({ key, ...rest }) => ({
  ...rest,
  value: JSON.stringify(key),
}));

export const getOptionLabel = (value) =>
  options.find(({ key }) => JSON.stringify(key) === value).label;

export const tagToUrl = {
  debitcardsupport: 'debit-card',
  depositaccountsupport: 'deposit-account',
  profilepreferences: 'profile-or-preferences',
  replace: 'replace-card', // for debit card
  other: 'support-portal',
  transactions: 'transaction-help', // for debit card
};

export const tagsCombination = options.map((o) => o.key);

export const availableTags = options.reduce((SetInstance, o) => {
  o.key.forEach((tag) => SetInstance.add(tag));
  return SetInstance;
}, new Set());
