const { REACT_APP_ENV } = process.env;

export const initPosthog = () => {
  if (REACT_APP_ENV === 'production') {
    const tag = document.createElement('script');
    tag.async = false;
    tag.src = 'https://posthog.datascience.hmb.sh/static/array.js';
    tag.onload = () => {
      if (window.posthog && window.posthog.init) {
        window.posthog.init('_v6DFN2vbEMbbngFoeQdcpCPQW7NzAjqtivUY05COiU', {
          api_host: 'https://posthog.datascience.hmb.sh',
        });
      }
    };
    const [body] = document.getElementsByTagName('body');
    body.appendChild(tag);
  }
};
