import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import './styles/header.scss';
import { logoutRequest } from '../../reducers/Auth';
import logo from '../../assets/logo.svg';

const Header = ({ user, userID, location, logoutRequest, history }) => {
  const showLoginLink =
    !user && !['/login', '/2fa/login'].includes(location.pathname);
  const showLogoutLink = user && location.pathname !== '/login';
  const logoutClick = () => {
    logoutRequest();
    history.push('/');
  };

  const logoClick = () => {
    if (location.pathname !== '/2fa/login' || !userID) {
      history.push('/contact-us');
    }
  };

  return (
    <div className="header">
      <div
        className="logo"
        role="button"
        tabIndex={0}
        onClick={logoClick}
        onKeyDown={logoClick}
      >
        <img src={logo} alt="Logo" />
      </div>

      {showLoginLink && (
        <Link
          data-testid="login"
          className="link login-link text-black text-decoration-none text-small-bold cursor-pointer"
          to="/login"
        >
          Log in
        </Link>
      )}

      {showLogoutLink && (
        <span
          role="button"
          tabIndex={0}
          onClick={logoutClick}
          onKeyDown={logoutClick}
          data-testid="logout"
          className="link text-black text-decoration-none text-small-bold cursor-pointer"
        >
          Logout
        </span>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  userID: state.Auth.userID,
});

Header.propTypes = {
  user: PropTypes.shape(),
  userID: PropTypes.string,
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  logoutRequest: PropTypes.func.isRequired,
};

Header.defaultProps = {
  user: null,
  userID: '',
};

export default withRouter(connect(mapStateToProps, { logoutRequest })(Header));
