import React from 'react';
import PropTypes from 'prop-types';
import './styles/index.scss';

const PageHeader = ({ children, text, title, withoutNav }) => (
  <div className="page-header">
    {!withoutNav && (
      <div className="navigation">
        {children}
        <p className="text-small">{text}</p>
      </div>
    )}
    <h2 className="mt-8">{title}</h2>
  </div>
);

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  text: PropTypes.string,
  title: PropTypes.string,
  withoutNav: PropTypes.bool,
};

PageHeader.defaultProps = {
  children: [],
  text: '',
  title: '',
  withoutNav: false,
};

export default PageHeader;
