import * as yup from 'yup';

import { formValidationFactory } from '../../utils/forms';

const validationSchema = yup
  .string()
  .required('A 6 digit verfication code is required')
  .matches(/[0-9]{6}/, 'Must be a string of 6 digits');

export const validateCode = formValidationFactory(validationSchema);
