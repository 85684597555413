import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import items from './items';
import LinksList from '../../../../components/LinksList/List';
import BackButton from '../../../../components/BackButton';
import PageHeader from '../../../../components/PageHeader';
import { pushTag, popTag } from '../../../../reducers/Tags';
import Layout from '../../../../components/Layout';

const TopicDebitCard = ({ pushTag, popTag }) => (
  <Layout>
    <PageHeader title="Please tell us more:">
      <BackButton clickCallback={popTag} to="/hmbradley-account" />
    </PageHeader>
    <div className="mt-16">
      <LinksList selectedCallback={pushTag} items={items} />
    </div>
  </Layout>
);

const mapDispatchToProps = {
  pushTag,
  popTag,
};

TopicDebitCard.propTypes = {
  pushTag: PropTypes.func.isRequired,
  popTag: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(TopicDebitCard);
