import React from 'react';
import PropTypes from 'prop-types';
import { REACT_APP_MAIN_DASHBOARD } from '../../utils/external-urls';
import { onNavigateListenerRN } from '../../utils/mobile-web-view';

const Layout = ({ children, className, withBackButton }) => {
  function onClick() {
    onNavigateListenerRN({ source: 'helpdesk' }, REACT_APP_MAIN_DASHBOARD);
  }

  return (
    <div className={`main-container mx-auto ${className}`}>
      <div className="page">
        {children}
        {withBackButton && (
          <div
            className="back-to-accounts-button cursor-pointer"
            onClick={onClick}
            onKeyDown={onClick}
            target="_blank"
            rel="noopener noreferrer"
            role="link"
            tabIndex={0}
          >
            Back to accounts
          </div>
        )}
      </div>
    </div>
  );
};

Layout.defaultProps = {
  children: null,
  className: '',
  withBackButton: true,
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
  ]),
  className: PropTypes.string,
  withBackButton: PropTypes.bool,
};

export default Layout;
