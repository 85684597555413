import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import './SplitScreen.scss';

const SplitScreen = ({ screens }) => (
  <Layout className="split-page" withBackButton={false}>
    <div className="split-screen">
      {screens.map(({ Component, isMobileScreen, key }, index) => (
        <Fragment key={key}>
          <div
            className={`split-screen-page ${
              isMobileScreen ? '' : 'hide-mobile'
            }`}
          >
            {Component}
          </div>
          {index !== screens.length - 1 && (
            <div className="hide-mobile">
              <div className="split-screen-line" />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  </Layout>
);

SplitScreen.defaultProps = {
  screens: [],
};

SplitScreen.propTypes = {
  screens: PropTypes.arrayOf(PropTypes.shape),
};

export default withRouter(SplitScreen);
