const COLORS = {
  black: '#00000',
  white: '#ffffff',
  orient: '#006982',
  blizzardBlue: '#96E6F0',
  pink: '#FFC5D2',
  passwordWrapper: '#6A6A6A',
  brandTeal: '#006982',
  lightBg: '#F5F5F5',
  fortune: '#EB3327',
  forbes: '#3E6296',
  techcrunch: '#0AA868',
  money: '#296695',
  darkGrey: '#323232',
  redOrange: '#EB5F2D',
  fontPlaceholderGrey: '#9f9f9f',
  fontInputGrey: '#666',
  lightLightGrey: '#aaa',
  lightGrey: '#989898',
};

export default COLORS;
