import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  checkAllowUploadDocuments,
  removeAllowUploadDocuments,
} from '../../utils/upload-documents';
import items from './items';
import LinksList from '../../components/LinksList/List';
import PageHeader from '../../components/PageHeader';
import { clearTags, pushTag } from '../../reducers/Tags';
import { getUserName } from '../../utils/user';
import { updateEmailSuccess } from '../../reducers/Email';
import {
  updateSuccessStatus,
  updateCodeVerified,
  updateProgress,
  updateProgressError,
  updateAgentName,
  updateCallingNumber,
} from '../../reducers/Code';
import { REACT_APP_MAIN_FAQ } from '../../utils/external-urls';
import Layout from '../../components/Layout';
import { tagsCombination, tagToUrl, availableTags } from '../../utils/tags';
import {
  onWidgetSuccess,
  onWidgetMinimised,
  showWidget,
  prefillUserData,
  hideWidget,
} from '../../utils/widget-zendesk';

const SelectTopic = ({
  user,
  clearTags,
  pushTag,
  updateEmailSuccess,
  updateSuccessStatus,
  updateCodeVerified,
  updateProgress,
  updateProgressError,
  updateAgentName,
  updateCallingNumber,
  location,
  history,
}) => {
  const allowUploadDocument = checkAllowUploadDocuments();

  useEffect(() => {
    clearTags();
    updateEmailSuccess(false);
    updateSuccessStatus(false);
    updateCodeVerified(false);
    updateProgress(false);
    updateProgressError(false);
    updateAgentName('');
    updateCallingNumber('');
  }, [
    user,
    clearTags,
    pushTag,
    updateEmailSuccess,
    updateSuccessStatus,
    updateCodeVerified,
    updateProgress,
    updateProgressError,
    updateAgentName,
    updateCallingNumber,
  ]);

  useEffect(() => {
    let tagsFromParams = new URLSearchParams(location.search).get('tags');
    if (tagsFromParams) {
      tagsFromParams = tagsFromParams.split(',');
      tagsFromParams.forEach((tag) => {
        pushTag(tag);
      });

      const isCombination = tagsCombination.find(
        (T) => T.toString() === tagsFromParams.toString(),
      );

      if (isCombination || tagsFromParams.some((t) => !availableTags.has(t))) {
        history.push('/support-portal');
      } else {
        const lastTag = tagsFromParams.pop();
        if (lastTag) {
          const url = tagToUrl[lastTag];
          if (url) history.push(url);
        }
      }
    }
  });

  useEffect(() => {
    if (allowUploadDocument) {
      setTimeout(() => {
        showWidget();
        prefillUserData(user.email, getUserName(user, ''));
        onWidgetMinimised(() => {
          hideWidget();
        });
        onWidgetSuccess(() => {
          removeAllowUploadDocuments();
        });
      }, [500]);
    }
  }, [allowUploadDocument, user]);

  return (
    <Layout>
      <PageHeader
        title={`Hello ${getUserName(
          user,
        )}, please select the topic you need help with.`}
      />
      <p className="text-small mt-8">
        Before we can assist you, we need a bit more information.
      </p>
      <div className="mt-16">
        <LinksList selectedCallback={pushTag} items={items} />
      </div>
      <p className="text-small mt-24">
        For any ongoing or future ACH transfer disputes, please reach out to{' '}
        <span className="text-small-bold">HMB_Inquiries@mynycb.com</span>.
        <br />
        <br />
        For support regarding an account closure check, please contact NYCB
        customer support at{' '}
        <span className="text-small-bold">877-786-6560</span>.
      </p>
      <div className="text-center text-small-bold">
        <p className="mt-24">
          Explore our FAQs{' '}
          <a
            rel="noopener noreferrer"
            className="text-teal"
            href={REACT_APP_MAIN_FAQ}
            target="_blank"
          >
            here.
          </a>
        </p>
        <p className="mt-16">
          Have a different question? Submit a form{' '}
          <Link className="text-teal" to="/support-email">
            here.
          </Link>
        </p>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.user,
});
const mapDispatchToProps = {
  pushTag,
  clearTags,
  updateCodeVerified,
  updateSuccessStatus,
  updateEmailSuccess,
  updateProgress,
  updateProgressError,
  updateAgentName,
  updateCallingNumber,
};

SelectTopic.propTypes = {
  pushTag: PropTypes.func.isRequired,
  clearTags: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    namePrefix: PropTypes.string,
    nameFirst: PropTypes.string,
    nameMiddle: PropTypes.string,
    nameLast: PropTypes.string,
  }).isRequired,
  updateEmailSuccess: PropTypes.func.isRequired,
  updateSuccessStatus: PropTypes.func.isRequired,
  updateCodeVerified: PropTypes.func.isRequired,
  updateProgress: PropTypes.func.isRequired,
  updateProgressError: PropTypes.func.isRequired,
  updateAgentName: PropTypes.func.isRequired,
  updateCallingNumber: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SelectTopic));
