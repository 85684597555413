import React from 'react';

import LinksList from '../../components/LinksList/List';
import BackButton from '../../components/BackButton';
import PageHeader from '../../components/PageHeader';
import Layout from '../../components/Layout';
import { REACT_APP_MAIN_FAQ } from '../../utils/external-urls';

const SupportPortalHome = () => {
  const items = [
    {
      template: <>Read FAQs</>,
      key: 'faqs',
      externalLink: REACT_APP_MAIN_FAQ,
    },
    {
      template: <>Send us an email</>,
      key: 'email',
      to: 'support-email',
    },
    // {
    //   template: <>Receive a phone call</>,
    //   key: 'phone-call',
    //   to: 'support-phone',
    // },
  ];

  return (
    <Layout>
      <PageHeader title="How would you like to speak with us">
        <BackButton to="/topics" />
      </PageHeader>
      <p className="text-small mt-8">
        Please select an option below so we can get you the right kind of
        assistance.
      </p>
      <div className="mt-16">
        <LinksList items={items} />
      </div>
      <p className="text-small mt-24">
        {/* Live phone support is available 9am - 5pm Pacific Time, Monday - Friday. */}
        If you need to report your credit card as lost or stolen, or lock your
        credit card, you can do so by navigating to "Manage Cards" on your
        HMBradley account.
      </p>
      <p className="text-small mt-24">
        For any ongoing or future ACH transfer disputes, please reach out to{' '}
        <span className="text-small-bold">HMB_Inquiries@mynycb.com</span>.
        <br />
        <br />
        For support regarding an account closure check, please contact NYCB
        customer support at{' '}
        <span className="text-small-bold">877-786-6560</span>.
      </p>
    </Layout>
  );
};

export default SupportPortalHome;
