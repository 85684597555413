import Socket from 'socket.io-client';

let socket;
if (process.env.NODE_ENV !== 'test') {
  const {
    REACT_APP_INTERNAL_SOCKET_URL = 'http://localhost:8000',
  } = process.env;
  socket = Socket(REACT_APP_INTERNAL_SOCKET_URL, { transports: ['websocket'] });
}

export const subscribeToVerification = (number, cb) => {
  if (socket) {
    socket.on('verification', cb);
    socket.emit('addClient', number);
  }
};

export const unsubscribeToVerification = () => {
  if (socket) {
    socket.removeEventListener('verification');
  }
};

export const subscribeToAssigned = (cb) => {
  if (socket) {
    socket.once('assigned', cb);
  }
};

export const subscribeToStatus = (cb) => {
  if (socket) {
    socket.on('status', cb);
  }
};

export const unsubscribeToStatus = () => {
  if (socket) {
    socket.removeEventListener('status');
  }
};
