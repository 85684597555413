import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';

import '../styles/Container.scss';

const Component = ({
  children,
  onClick,
  onClickOutside,
  isActive,
  hasError,
  textarea,
}) => {
  const containerRef = useRef(null);
  useClickOutsideHandler(containerRef, onClickOutside);

  const styles = ['container'];
  if (isActive) {
    styles.push('container_active');
  }
  if (hasError) {
    styles.push('error');
  }
  if (textarea) {
    styles.push('textarea-container');
  }

  return (
    <div
      role="presentation"
      ref={containerRef}
      onClick={onClick}
      onKeyDown={() => {}}
      className={styles.join(' ')}
    >
      {children}
    </div>
  );
};

Component.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  onClickOutside: PropTypes.func,
  isActive: PropTypes.bool,
  hasError: PropTypes.bool,
  textarea: PropTypes.bool,
};

Component.defaultProps = {
  onClick: () => {},
  onClickOutside: () => {},
  isActive: false,
  hasError: false,
  textarea: false,
};

Component.displayName = 'InputContainer';

export default Component;
