import {
  SET_VALUE,
  SET_ERROR,
  SET_FOCUSED,
  SET_TOUCHED,
  REGISTER_REQUIRED_FIELD,
} from './fieldActions';

// action
//   type
//   fieldname
//   data (optional)
const inputReducer = (state, action) => {
  switch (action.type) {
    case SET_VALUE:
      return {
        ...state,
        value: action.data,
      };
    case SET_ERROR:
      return {
        ...state,
        errors: action.errors.map((err) => err[1]),
      };
    case SET_FOCUSED:
      return {
        ...state,
        focused: true,
      };
    case SET_TOUCHED:
      if (state.focused) {
        return {
          ...state,
          focused: false, // touched = onBlur
          touched: true,
        };
      }

      return state;
    case REGISTER_REQUIRED_FIELD:
      return {
        ...state,
        required: true,
      };
    default:
      return state;
  }
};

export default inputReducer;
