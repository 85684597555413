import React from 'react';
import PropTypes from 'prop-types';

const Layout = ({ children, className }) => {
  return (
    <div className={`main-container mx-auto ${className}`}>
      <div className="page">{children}</div>
    </div>
  );
};

Layout.defaultProps = {
  children: null,
  className: '',
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
  ]),
  className: PropTypes.string,
};

export default Layout;
