import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import items from './items';
import LinksList from '../../../components/LinksList/List';
import BackButton from '../../../components/BackButton';
import PageHeader from '../../../components/PageHeader';
import { pushTag } from '../../../reducers/Tags';
import Layout from '../../../components/Layout';

const DepositAccount = ({ pushTag }) => (
  <Layout>
    <PageHeader title="Please tell us more:">
      <BackButton to="/topics" />
    </PageHeader>
    <div className="mt-16">
      <LinksList selectedCallback={pushTag} items={items} />
    </div>
  </Layout>
);

const mapDispatchToProps = {
  pushTag,
};

DepositAccount.propTypes = {
  pushTag: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(DepositAccount);
