import React from 'react';

export default [
  {
    template: (
      <>
        I need <span className="text-teal">deposit account support</span>
      </>
    ),
    to: '/deposit-account',
    key: 'depositaccountsupport',
  },
];
