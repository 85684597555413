import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import PageHeader from '../../../components/PageHeader';
import Layout from '../../../components/Layout';
import SubmitButton from '../../../components/Buttons/SubmitButton';

import { postMessageToWebView } from '../../../utils/mobile-web-view';
import { REACT_APP_MAIN_DASHBOARD } from '../../../utils/external-urls';

const voicemailString =
  'Please leave a voicemail and we will get back to you as soon as possible.';

const Verified = ({ progress, agentName, progressError, tags }) => {
  const notConnected = !progress || !agentName;
  const agentString =
    agentName === 'voicemail'
      ? voicemailString
      : `You have been connected to ${agentName || 'an HMBradley Agent'}.`;

  const onGoToHMB = () => {
    postMessageToWebView(
      {
        flow: 'contact-support',
        via: 'phone',
        subject: tags,
        action: 'go-to-hmb',
        status: 'success',
      },
      () => window.open(REACT_APP_MAIN_DASHBOARD, '_blank'),
    );
  };

  return (
    <Layout withBackButton={false}>
      {progressError && <Redirect to="/topics" />}
      <PageHeader
        title={notConnected ? 'Connecting you to an agent ...' : agentString}
      />
      {notConnected && (
        <>
          <p className="text-small mt-8">
            You will be re-directed to your account dashboard momentarily.
          </p>
          <div className="loader-wrapper mt-16">
            <Loader type="Oval" height={50} width={50} color="#000" />
          </div>
        </>
      )}
      {!notConnected && (
        <div className="mt-12 text-center">
          <Link to="/contact-us">
            <SubmitButton label="Support Home" />
          </Link>
          <div
            tabIndex={0}
            role="button"
            onKeyDown={onGoToHMB}
            onClick={onGoToHMB}
            className="mt-8 cursor-pointer"
          >
            <span
              className="text-small-bold text-teal"
              style={{ textDecoration: 'underline' }}
            >
              Go to my HMBradley Account
            </span>
          </div>
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  progress: state.Code.progress,
  progressError: state.Code.progressError,
  agentName: state.Code.agentName,
  tags: state.Tags.tags,
});

Verified.propTypes = {
  progress: PropTypes.bool.isRequired,
  agentName: PropTypes.string.isRequired,
  progressError: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default connect(mapStateToProps, null)(Verified);
