/* eslint react/button-has-type:0 */
import React from 'react';
import PropTypes from 'prop-types';
import LoadingAnimation, { MEDIUM } from '../LoadingAnimation';

import './styles/SubmitButton.scss';

const Component = ({ label, disabled, onClick, loading }) => {
  const buttonStyles = ['button_box'];
  if (disabled || loading) buttonStyles.push('disabled');

  const typeButton = onClick ? 'button' : 'submit';
  return (
    <button
      data-testid="submit-btn"
      type={typeButton}
      className={buttonStyles.join(' ')}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? <LoadingAnimation size={MEDIUM} color="#ffffff" /> : label}
    </button>
  );
};

Component.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  label: 'Submit',
  disabled: false,
  loading: false,
  onClick: null,
};

Component.displayName = 'SubmitButton';

export default Component;
