import React from 'react';

export default [
  {
    template: (
      <>
        I need <span className="text-teal">credit card support</span>
      </>
    ),
    to: '/credit-card-support',
    key: 'creditcardsupport',
  },
  {
    template: (
      <>
        I need <span className="text-teal">credit account support</span>
      </>
    ),
    to: '/credit-account-support',
    key: 'creditaccountsupport',
  },
];
