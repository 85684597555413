import React from 'react';

export default [
  {
    template: (
      <>
        I need to <span className="text-teal">dispute</span> a transaction
      </>
    ),
    to: '/support-portal',
    key: 'dispute',
  },
  {
    template: (
      <>
        I need help with <span className="text-teal">something else</span>
      </>
    ),
    transparent: true,
    to: '/support-portal',
    key: 'other',
  },
];
