import React from 'react';

export default [
  {
    template: (
      <>
        I need help with my <span className="text-teal">HMBradley Account</span>
      </>
    ),
    to: '/hmbradley-account',
    key: 'hmbaccount',
  },
  {
    template: (
      <>
        I need help with my{' '}
        <span className="text-teal">HMBradley Credit Card</span>
      </>
    ),
    to: '/credit-account',
    key: 'hmbcreditcard',
  },

  {
    template: (
      <>
        I need help with my{' '}
        <span className="text-teal">profile or preferences</span>
      </>
    ),
    to: '/profile-or-preferences',
    key: 'profilepreferences',
  },
];
