export function onCloseWidget(callback) {
  if (window.zE) {
    window.zE('webWidget:on', 'close', callback);
  }
}

export function onWidgetUserEvent(callback) {
  if (window.zE) {
    window.zE('webWidget:on', 'userEvent', callback);
  }
}

export function onWidgetSuccess(callback) {
  if (window.zE) {
    window.zE('webWidget:on', 'userEvent', (e) => {
      if (e.action === 'Contact Form Submitted') {
        callback();
      }
    });
  }
}

export function onWidgetMinimised(callback) {
  if (window.zE) {
    window.zE('webWidget:on', 'userEvent', (e) => {
      if (e.action === 'Web Widget Minimised') {
        callback();
      }
    });
  }
}

export function hideWidget(withInterval) {
  if (withInterval) {
    const interval = setInterval(() => {
      if (window.zE) {
        window.zE('webWidget', 'hide');
        clearInterval(interval);
      }
    }, 10);
  } else {
    window.zE('webWidget', 'hide');
  }
}

export function prefillUserData(email, name) {
  if (window.zE) {
    window.zE.identify({ name, email });
  }
}

export function showWidget() {
  if (window.zE) {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
  }
}
