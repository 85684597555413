import * as yup from 'yup';

import { fieldValidatorBase, formValidationFactory } from '../../utils/forms';

const validationSchema = yup.object().shape({
  username: yup.string().required('Please enter your username'),
  password: yup.string().required('Please enter your password'),
});

const fieldValidator = fieldValidatorBase(validationSchema);

export const validateUsername = fieldValidator('username');
export const validatePassword = fieldValidator('password');

export const validation = formValidationFactory(validationSchema);
