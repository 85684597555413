import * as yup from 'yup';

export const fieldValidatorBase = (validationSchema) => (
  fieldname,
  extraValidation = false,
) => async (val) => {
  try {
    await yup
      .reach(validationSchema, fieldname)
      .validate(val, { abortEarly: false });

    if (extraValidation) {
      return await extraValidation(val);
    }
  } catch (e) {
    return e.errors;
  }

  return [];
};

export const formValidationFactory = (
  validationSchema,
  extraValidations = {},
) => async (data) => {
  try {
    await validationSchema.validate(data, { abortEarly: false });
    const extraKeys = Object.keys(extraValidations);
    if (extraKeys.length) {
      const extraResults = await Promise.all(
        extraKeys.map((key) => extraValidations[key](data[key])),
      );
      if (extraResults.some((result) => result.length)) {
        return extraResults.map((result, index) => [extraKeys[index], result]);
      }
    }
    return [];
  } catch (err) {
    const validationErrors = err.inner.map((error) => [
      error.path,
      error.errors[0],
    ]);
    return validationErrors;
  }
};

export const phoneTest = /^[1-9]\d{2}-\d{3}-\d{4}/;

export const preparePhoneNumber = (phoneNumber) => {
  const insert = (str, i, ins) => [str.slice(0, i), ins, str.slice(i)].join('');
  let res = phoneNumber.replace('+1', '');
  res = insert(res, 3, '-');
  res = insert(res, 7, '-');
  return res;
};

export const preparePhoneNumberToServer = (phoneNumber) =>
  `+1${phoneNumber.replace('+1', '').replace(/-/g, '')}`;
export const phoneNumberToInteger = (phoneNumber) =>
  phoneNumber.toString().replace('+', '');
export const wait = (timestamp) =>
  new Promise((res) => setTimeout(res, timestamp));
