import React from 'react';

export default [
  {
    template: (
      <>
        I have a question about my <span className="text-teal">profile</span>
      </>
    ),
    to: '/support-portal',
    key: 'personalinformation',
  },
  {
    template: (
      <>
        I need help with <span className="text-teal">something else</span>
      </>
    ),
    transparent: true,
    to: '/support-portal',
    key: 'other',
  },
];
