import { toast } from 'react-toastify';
import {
  getUser,
  getPerson,
  login,
  logout,
  verifyEmail,
  resendVerificationCode,
  getAccounts,
  login2FA,
  resend2FACode,
} from '../api/modules/auth';

export const LOGOUT_SUCCESS = 'LOGOUT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_2FA_ERROR = 'LOGIN_2FA_ERROR';

export const authSuccess = (payload) => ({ type: AUTH_SUCCESS, payload });
export const authError = () => ({ type: AUTH_ERROR });
export const login2FAError = (payload) => ({ type: LOGIN_2FA_ERROR, payload });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });

export const logoutRequest = () => async (dispatch) => {
  try {
    await logout();
    dispatch(logoutSuccess());
  } catch (error) {
    dispatch(authError(error));
  }
};

export const getPersonRequest = () => async (dispatch) => {
  let person;
  let accounts;
  try {
    ({ data: person } = await getPerson());
  } catch (error) {
    person = null;
  }
  try {
    ({
      data: { data: accounts },
    } = await getAccounts());
  } catch (error) {
    accounts = [];
  }
  dispatch(authSuccess({ accounts, person, userLoaded: true }));
};

export const getUserRequest = () => async (dispatch) => {
  try {
    const { data: user } = await getUser();
    dispatch(authSuccess({ user, isVerified: user.isVerified }));
    dispatch(getPersonRequest());
  } catch (error) {
    dispatch(authError(error));
  }
};

export const loginRequest = (requestData) => async (dispatch) => {
  try {
    const { data, status } = await login(requestData);
    if (status === 242) {
      dispatch(authSuccess(data));
    } else {
      dispatch(getUserRequest());
    }
  } catch (error) {
    dispatch(authError(error));
    throw new Error(error);
  }
};

export const login2FARequest = (requestData) => async (dispatch) => {
  try {
    await login2FA(requestData);
    dispatch(getUserRequest());
  } catch (error) {
    dispatch(login2FAError(error.message));
    throw new Error(error);
  }
};

export const resend2FACodeRequest = (requestData) => async () => {
  try {
    await resend2FACode(requestData);
    toast.success('Sent! Please check your phone.');
  } catch (error) {
    toast.error(error.message || error);
  }
};

export const verifyEmailRequest = (requestData) => async (dispatch) => {
  try {
    await verifyEmail(requestData);
    dispatch(authSuccess({ isVerified: true }));
    dispatch(getPersonRequest());
  } catch (error) {
    if (error.message.includes('401')) {
      throw new Error(
        'Invalid verification code. Try again or click "Resend code" below to get a new code sent to your email.',
      );
    }
    throw new Error(error.message);
  }
};

export const resendCodeRequest = (email) => async () => {
  try {
    await resendVerificationCode({ email });
    toast.success('New verification code was sent!');
  } catch (error) {
    toast.error(error.message || error);
  }
};

const DEFAULT_STATE = {
  user: null,
  userID: '',
  userLoaded: false,
  twoFactorID: '',
  login2FAError: '',
  person: null,
  isVerified: false,
  accounts: [],
};

export default function reducer(state = DEFAULT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        ...payload,
        login2FAError: '',
        userLoaded: true,
      };
    case AUTH_ERROR:
      return { ...DEFAULT_STATE, userLoaded: true };
    case LOGIN_2FA_ERROR:
      return { ...state, login2FAError: payload };
    case LOGOUT_SUCCESS:
      return {
        ...DEFAULT_STATE,
        userLoaded: true,
        login2FAError: state.login2FAError,
      };
    default:
      return state;
  }
}
