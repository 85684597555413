import React from 'react';
import PropTypes from 'prop-types';

import COLORS from '../../styles/colors';

import './styles.scss';

export const LARGE = 'large';
export const MEDIUM = 'medium';
export const SMALL = 'small';

const Component = ({ size, color }) => {
  let diameter;
  switch (size) {
    case LARGE:
      diameter = 36;
      break;
    case MEDIUM:
      diameter = 24;
      break;
    case SMALL:
      diameter = 16;
      break;
    default:
      diameter = 24;
      break;
  }

  return (
    <svg
      width={diameter}
      height={diameter}
      x="0"
      y="0"
      viewBox={`0 0 ${diameter} ${diameter}`}
    >
      <circle
        className={`path_${size}`}
        cx={diameter / 2}
        cy={diameter / 2}
        r={diameter / 2 - 4}
        stroke={color}
        fill="none"
      />
    </svg>
  );
};

Component.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

Component.defaultProps = {
  size: MEDIUM,
  color: COLORS.white,
};

Component.displayName = 'LoadingAnimation';

export default Component;
