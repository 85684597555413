import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import reducer from './fieldReducer';

export const FieldContext = React.createContext();

const Component = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(reducer, {
    value: initialState,
    errors: [],
    touched: false,
    required: false,
  });

  const provide = {
    state,
    dispatch,
  };

  return (
    <FieldContext.Provider value={provide}>{children}</FieldContext.Provider>
  );
};

Component.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  initialState: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
};

Component.defaultProps = {
  initialState: '',
};

Component.displayName = 'Field';

export default Component;
