import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import './styles/index.scss';

const BackButton = ({ clickCallback, history, to }) => {
  const onClick = () => {
    if (to) {
      history.push(to);
    }
    clickCallback();
  };

  return (
    <div
      tabIndex={0}
      role="button"
      onKeyDown={onClick}
      onClick={onClick}
      className="back-button text-small-bold cursor-pointer"
    >
      <span>{'<'} </span>
      <span>Back</span>
    </div>
  );
};

BackButton.propTypes = {
  clickCallback: PropTypes.func,
  to: PropTypes.string,
  history: PropTypes.shape().isRequired,
};

BackButton.defaultProps = {
  to: '',
  clickCallback: () => {},
};

export default withRouter(BackButton);
