export function setAllowUploadDocuments() {
  localStorage.setItem('allowUploadDocuments', 'true');
}

export function checkAllowUploadDocuments() {
  return localStorage.getItem('allowUploadDocuments') === 'true';
}

export function removeAllowUploadDocuments() {
  localStorage.removeItem('allowUploadDocuments');
}
