import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  login2FARequest,
  logoutRequest,
  resend2FACodeRequest,
} from '../../reducers/Auth';
import PageHeader from '../../components/PageHeader';
import Form from '../../components/Form/Form';
import Otp from '../../components/Form/InputTypes/Otp';
import Layout from '../../components/Layout';
import { validateCode } from './validation';

const Login2FA = ({
  user,
  userID,
  login2FARequest,
  logoutRequest,
  twoFactorID,
  login2FAError,
  resend2FACodeRequest,
}) => {
  const [triesCount, setTriesCount] = useState(0);
  const [sentSuccess, setSentSuccess] = useState(false);

  useEffect(() => {
    if (triesCount >= 3) {
      logoutRequest();
    }
  }, [triesCount, logoutRequest]);
  const initialState = { code: '' };

  const onSubmit = async ({ code }) => {
    try {
      await login2FARequest({ code, twoFactorID });
    } catch (error) {
      setTriesCount(triesCount + 1);
      throw new Error('');
    }
  };

  const onResend = async () => {
    if (!sentSuccess) {
      try {
        await resend2FACodeRequest({ userID });
        setSentSuccess(true);
      } catch (error) {
        setSentSuccess(false);
      }
    }
  };

  if (user) {
    return <Redirect to="/topics" />;
  }

  if (!twoFactorID && login2FAError) {
    return (
      <Layout className="split-page">
        <PageHeader
          withoutNav
          title="You have been locked out of your account."
        />
        <p className="text-small mt-12">
          You have entered an incorrect code too many times, so we have locked
          this account. We apologize for the inconvenience.
        </p>
      </Layout>
    );
  }

  const header = login2FAError ? 'Please try again.' : 'Enter your unique code';

  const subheader = login2FAError
    ? 'The code you have entered is incorrect or has expired. Please check the code or click "Resend code" if you need a new code.'
    : 'Please enter the 6-digit code from your authenticator app.';

  return (
    <Layout className="split-page">
      <PageHeader withoutNav title={header} />
      <p className="text-small mt-12">{subheader}</p>
      <div className="mt-16">
        <Form
          initialState={initialState}
          validationFunc={() => []}
          onSubmit={onSubmit}
          submitLabel="Submit"
        >
          <Otp fieldname="code" validationFunc={validateCode} required />
        </Form>
        <div
          className={`link-button mx-auto mt-16 ${
            sentSuccess ? 'disable' : ''
          }`}
          role="button"
          tabIndex={0}
          onClick={onResend}
          onKeyPress={onResend}
        >
          Resend code
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  twoFactorID: state.Auth.twoFactorID,
  login2FAError: state.Auth.login2FAError,
  userID: state.Auth.userID,
  user: state.Auth.user,
});
const mapDispatchToProps = {
  login2FARequest,
  logoutRequest,
  resend2FACodeRequest,
};

Login2FA.propTypes = {
  user: PropTypes.shape(),
  login2FAError: PropTypes.string,
  userID: PropTypes.string,
  twoFactorID: PropTypes.string,
  login2FARequest: PropTypes.func.isRequired,
  logoutRequest: PropTypes.func.isRequired,
  resend2FACodeRequest: PropTypes.func.isRequired,
};

Login2FA.defaultProps = {
  user: null,
  twoFactorID: '',
  userID: '',
  login2FAError: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(Login2FA);
