import React from 'react';

export default [
  {
    template: (
      <>
        I need help with my <span className="text-teal">interest rate</span>
      </>
    ),
    to: '/support-portal',
    key: 'interestrates',
  },
  {
    template: (
      <>
        I need help with my <span className="text-teal">Plans</span>
      </>
    ),
    to: '/support-portal',
    key: 'plans',
  },
  {
    template: (
      <>
        I need help with a <span className="text-teal">transfer</span>
      </>
    ),
    to: '/support-portal',
    key: 'transfers',
  },
  {
    template: (
      <>
        I need help with <span className="text-teal">requesting a wire</span>
      </>
    ),
    to: '/wire-details',
    key: 'depo/wire_request',
  },
  {
    template: (
      <>
        I need help with my <span className="text-teal">linked accounts</span>
      </>
    ),
    to: '/support-portal',
    key: 'linkedaccounts',
  },
  {
    template: (
      <>
        I need help with <span className="text-teal">mobile check deposit</span>
      </>
    ),
    to: '/support-portal',
    key: 'mobilecheckdeposit',
  },
  {
    template: (
      <>
        I need help with <span className="text-teal">direct deposit</span>
      </>
    ),
    to: '/support-portal',
    key: 'directdeposit',
  },
  {
    template: (
      <>
        I need help with <span className="text-teal">something else</span>
      </>
    ),
    transparent: true,
    to: '/support-portal',
    key: 'other',
  },
];
